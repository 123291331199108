<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

* {
  touch-action: pan-x pan-y;
  padding: 0;
  margin: 0;
  color: var(--app-color);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 250ms linear, border-color 250ms linear;
  -ms-transition: background-color 250ms linear, border-color 250ms linear;
  transition: background-color 250ms linear, border-color 250ms linear;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background: var(--app-background);
}

#content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#appContent {
  width: calc(100% - 101px);
  height: 100%;
  overflow: hidden;
}

#appContent.extended {
  width: 100%;
}

svg {
  box-sizing: content-box;
}

/* SCROLLBAR */
::-webkit-scrollbar { width: 0px; }
::-webkit-scrollbar-track { background: #f1f1f1; }
::-webkit-scrollbar-thumb { background: #d5d5d5; }
::-webkit-scrollbar-thumb:hover { background: #555; }
</style>