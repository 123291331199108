import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

let app = createApp(App)

// Lottie
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
app.use(Vue3Lottie)

// Axios
app.config.globalProperties.$http = axios

app.use(router).mount('#app')